@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@200&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: black;
}
.center {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
}

.centerText{
  text-align: center;
}

.navbar{
  background: black;
  width:100%;
}

.navbar ul{
  background: rgba(255, 255, 255, 0);
}

.navbar-brand{
  color: black;
  font-size: 55px;
  margin-left: 40px;
  display:inline;
}


.navbar-link{
  color: black;
  font-size: 30px;
  font-weight:bold;
}

.navbar a{
  font-family: 'Nunito Sans', sans-serif;
}

.footer{
  border-top: 2px solid black;
  width:100%;
  padding: 2px 2px 
}
.about-me{
  color: black;
  font-family: 'Nunito Sans';
  font-size: 24px;
  padding: 2px 2px;
  font-weight:bold;
  width:100%;
}

.footer-a{
  font-family: 'Marcellus';
  color: black;
  font-size: 28px;
  text-decoration: none; 
  width:100%;
  display:inline;
  text-align:center;
}

.footer-bottom{
  color: black;
  font-family: 'Nunito Sans';
  text-align:right;
  font-weight:bold;
  width:100%;
}

.footer-container a{
  font-family: 'Nunito Sans', sans-serif;
  color: black;
  font-size: 20px;
  width:100%;
  text-align:right;
  display:block;
  font-weight:bold;
}

.galleryTitle{
  margin-top: 10px;
}
h1{
  font-family: 'Marcellus';
}
h2{
  font-family: 'League Spartan', sans-serif;
}

h6{
  color:#3B3B3B;
  font-family: 'League Spartan', sans-serif;
  font-weight: bold;
}
hr{
  width:40%;
  margin: auto;
}
.frameSquare {
  width: 450px;
  height: 450px;
  border: 1px solid whitesmoke;
  background: #FAF9F6;
  margin: auto;
  padding: 5px 5px;
}

.frameWide {
  width: 700px;
  height: 450px;
  border: 1px solid whitesmoke;
  background: #FAF9F6;
  margin: auto;
  padding: 5px 5px;
}

.formHeader{
  margin-top: 50px;
}

div h1{
    padding-right:150px;
}
.imageContainer{
  margin-top: 50px;
  margin-bottom: 100px;
}

#image-section{
  width: 100%;
  height: auto;
};

.imgBox{
  max-width: 400px;
  min-width: 200px;
};

.galleryPicture{
  margin-top: 10px;
  margin-bottom: 10px;
}

.po_items_ho {
  column-width: 20em;
  -moz-column-width: 20em;
  -webkit-column-width: 20em;
  column-gap: 5rem;
  -moz-column-gap: 5rem;
  -webkit-column-gap: 5rem;
}

.po_item {
  display: inline-block;
  text-align: auto;
  margin: 0.5rem auto;
  position: relative;
  background: black;
  padding: 6px;
  border: 1px solid white;
  transition: 0.3s ease;
  font-size: 0;
  width: 100%;
  height: 500px;
}

.po_item img {
  max-height:500px;
  width: 100%;
  object-fit:contain;
  overflow:auto;
}

.po_item .content {
  position: absolute;
  height: 0;
  width: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--overlay-color);
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  transition: 0.3s ease-in-out;
  opacity: 0;
  border: 1px solid black;
}

.po_item .content {
  opacity: 0;
  transition-delay: 1s;
  transition: 0.3s ease;
  font-size: 15px;
}

.po_item:hover {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25);
}

.po_item:hover .content {
  height: calc(100% - 30px);
  width: calc(100% - 30px);
  opacity: 1;
}

.po_item:hover .content {
  opacity: 0.8;
  color: black;
  background: white;
}

.container a{
  color: black;
  font-size: 20px;
  width: 100%;
}

.footer-bs{
  position: absolute;
  width: 100%;
  min-height: 320px;
  bottom: 40px;
  left: 0;
  color: black;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
